.App {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_container {
  width: 100%;
  max-width: 400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.button_container {
  font-size: 12px;
  padding-inline: 20px;
  padding-top: 50px;
  box-sizing: border-box;
}

.button_container a {
  border: 1px solid #000;
  flex: 1;
  max-height: 50px;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}